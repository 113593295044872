<template>
  <div>
    <b-row class="no-gutters">
      <b-col cols="6">
        <h1 class="font-weight-bold header-main text-uppercase mb-3">
          รายละเอียดข้อมูลเจ้าหน้าที่
        </h1>
      </b-col>
      <!-- <b-col cols="6" class="text-right">
          <span class="label-text mr-3 txt-relative">ระงับการใช้งาน</span>
          <b-form-checkbox
            switch
            size="lg"
            class="d-inline-block mb-1"
          ></b-form-checkbox>
        </b-col> -->
    </b-row>
    <div class="bg-white p-3">
      <b-row v-if="isLoadingData">
        <b-col class="px-4 px-sm-5 py-4 vh-100">
          <img src="/img/loading.svg" class="loading" alt="loading" />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <InputText
            textFloat="ชื่อ"
            placeholder="ชื่อ"
            type="text"
            name="firstname"
            isRequired
            v-model="form.userProfile.firstname"
            :isValidate="$v.form.userProfile.firstname.$error"
            :v="$v.form.userProfile.firstname"
          />
        </b-col>
        <b-col md="6">
          <InputText
            textFloat="นามสกุล"
            placeholder="นามสกุล"
            type="text"
            name="lastname"
            isRequired
            v-model="form.userProfile.lastname"
            :isValidate="$v.form.userProfile.lastname.$error"
            :v="$v.form.userProfile.lastname"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <InputText
            textFloat="อีเมล์"
            placeholder="อีเมล์"
            type="text"
            name="email"
            isRequired
            :disabled="id !== '0'"
            v-model="form.userProfile.email"
            :isValidate="$v.form.userProfile.email.$error"
            :v="$v.form.userProfile.email"
          />
        </b-col>
        <b-col md="6">
          <InputText
            textFloat="เบอร์โทรศัพท์"
            placeholder="เบอร์โทรศัพท์"
            type="text"
            name="telephone"
            isRequired
            v-model="form.userProfile.telephone"
            :isValidate="$v.form.userProfile.telephone.$error"
            :v="$v.form.userProfile.telephone"
            @onkeypress="isNumber($event)"
          />
        </b-col>
      </b-row>

      <b-row v-if="id == 0">
        <b-col md="6">
          <InputText
            textFloat="รหัสผ่าน"
            placeholder="รหัสผ่าน"
            type="password"
            name="password"
            isRequired
            v-model="form.userProfile.password"
            :isValidate="$v.form.userProfile.password.$error"
            :v="$v.form.userProfile.password"
          />
        </b-col>
        <b-col md="6">
          <InputText
            textFloat="ยืนยันรหัสผ่าน"
            placeholder="ยืนยันรหัสผ่าน"
            type="password"
            name="confirmPassword"
            isRequired
            v-model="form.userProfile.confirmPassword"
            :isValidate="$v.form.userProfile.confirmPassword.$error"
            :v="$v.form.userProfile.confirmPassword"
          />
        </b-col>
      </b-row>
      <template v-if="id != 0 && profileInfo.roleId == 99">
        <b-row>
          <b-col md="6">
            <InputText
              textFloat="รหัสผ่าน"
              placeholder="รหัสผ่าน"
              type="password"
              name="password"
              isRequired
              v-model="formPassword.password"
              :isValidate="$v.formPassword.password.$error"
              :v="$v.formPassword.password"
              isShowPassword
            />
          </b-col>
          <b-col md="6">
            <InputText
              textFloat="ยืนยันรหัสผ่าน"
              placeholder="ยืนยันรหัสผ่าน"
              type="password"
              name="confirmPassword"
              isRequired
              v-model="formPassword.confirmPassword"
              :isValidate="$v.formPassword.confirmPassword.$error"
              :v="$v.formPassword.confirmPassword"
              isShowPassword
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <span class="detail-password"
              >*รหัสผ่านต้องมีจำนวนไม่น้อยกว่า 8 ตัว
              และต้องประกอบด้วยตัวอักษรภาษาอังกฤษ/ตัวเลข</span
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="text-right">
            <button
              type="button"
              @click="checkFormPassword()"
              :disabled="isDisable"
              class="btn btn-main btn-details-set text-uppercase"
            >
              เปลี่ยนรหัสผ่าน
            </button>
          </b-col>
        </b-row>
      </template>
      <b-row class="mt-1">
        <b-col>
          <label class="font-weight-bold main-label">
            สถานะการแสดงผล
            <span class="text-danger">*</span>
          </label>
          <div>
            <b-form-checkbox
              switch
              v-model="form.userProfile.enabled"
              class="radio-active"
              size="lg"
            >
              <span class="ml-2 main-label">{{
                form.userProfile.enabled ? "ใช้งาน" : "ไม่ใช้งาน"
              }}</span>
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <h4 class="f-16 font-weight-bold">เมนูที่มีสิทธิ์เข้าถึง</h4>
        </b-col>
      </b-row>

      <div class="store-type-box px-4 mb-4">
        <div>
          <template v-for="(item2, key2) in menus">
            <div :key="`lv2-${key2}`">
              <div class="text-left my-3">
                <div class="pl-lv2">
                  <b-button
                    variant="toggle-tree"
                    v-if="
                      parentList.indexOf(item2.id) < 0 &&
                      item2.subMenu.length > 0
                    "
                    @click="addParent(item2.id)"
                    ref="expandAll"
                    class="mr-2"
                  >
                    <font-awesome-icon icon="chevron-right" class="menu" />
                  </b-button>
                  <b-button
                    variant="toggle-tree"
                    class="mr-2"
                    v-else-if="
                      parentList.indexOf(item2.id) >= 0 &&
                      item2.subMenu.length > 0
                    "
                    @click="deleteParent(item2.id, 1)"
                  >
                    <font-awesome-icon icon="chevron-down" class="menu" />
                  </b-button>
                  <b-button
                    v-else
                    variant="toggle-tree"
                    class="mr-2"
                    :style="{ opacity: 0 }"
                  >
                  </b-button>
                  <div class="parent-cat">
                    <b-form-checkbox
                      size="lg"
                      :value="item2.id"
                      v-model="form.permission"
                      @change="checkSubMenuChecked(item2.id, item2.subMenu)"
                    >
                      <span>{{ item2.name }}</span>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <template v-for="(item3, key3) in item2.subMenu">
              <div
                :key="`lv2-${key2}-lv3-${key3}`"
                v-if="parentList.indexOf(item2.id) > -1"
              >
                <div class="text-left my-3 pl-lv4">
                  <div class="ml-4">
                    <b-button
                      variant="toggle-tree"
                      v-if="
                        parentList.indexOf(item3.id) < 0 &&
                        item3.subMenu &&
                        item3.subMenu.length > 0
                      "
                      @click="addParent(item3.id)"
                      ref="expandAll"
                      class="mr-2"
                    >
                      <font-awesome-icon icon="chevron-right" class="menu" />
                    </b-button>
                    <b-button
                      variant="toggle-tree"
                      class="mr-2"
                      v-else-if="
                        parentList.indexOf(item3.id) >= 0 &&
                        item3.subMenu &&
                        item3.subMenu.length > 0
                      "
                      @click="deleteParent(item3.id, item2.id)"
                    >
                      <font-awesome-icon icon="chevron-down" class="menu" />
                    </b-button>
                    <b-button
                      v-else
                      variant="toggle-tree"
                      class="mr-2"
                      :style="{ opacity: 0 }"
                    >
                    </b-button>
                    <div class="parent-cat">
                      <b-form-checkbox
                        size="lg"
                        class="mt-1 ml-4"
                        v-bind:key="key3"
                        :value="item3.id"
                        v-model="form.permission"
                        @change="checkSubMenuChecked(item3.id, item3.subMenu)"
                      >
                        <span>{{ item3.name }}</span>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>

              <template v-for="(item4, key4) in item3.subMenu">
                <div
                  :key="`lv3-${key3}-lv4-${key4}`"
                  v-if="parentList.indexOf(item3.id) > -1"
                >
                  <div class="text-left my-3 pl-lv4 ml-5">
                    <div class="ml-5">
                      <div class="parent-cat">
                        <b-form-checkbox
                          size="lg"
                          class="mt-1 ml-4"
                          v-bind:key="key4"
                          :value="item4.id"
                          v-model="form.permission"
                          @change="checkSubMenuChecked(item4.id, item4.subMenu)"
                        >
                          <span>{{ item4.name }}</span>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
      </div>

      <b-row class="mt-1">
        <b-col md="6">
          <b-button
            href="/admin"
            :disabled="isDisable"
            class="btn-details-set btn-cancel"
            >ย้อนกลับ</b-button
          >
        </b-col>
        <b-col md="6" class="text-sm-right">
          <button
            type="button"
            @click="checkForm()"
            :disabled="isDisable"
            class="btn btn-main btn-details-set ml-md-2 text-uppercase"
          >
            บันทึก
          </button>
        </b-col>
      </b-row>
    </div>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import { mapState } from "vuex";

import Vue from "vue";
import {
  required,
  integer,
  minLength,
  helpers,
  sameAs,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
const password = helpers.regex(
  "password",
  /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9!-_]+)$/
);

export default {
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
  },
  data() {
    return {
      id: this.$route.params.id,
      items: [],
      menus: [],
      parentList: [],
      isDisable: false,
      isLoadingData: false,
      modalMessage: "",
      isBusy: false,
      value: false,
      rows: 0,
      verifiedColor: "",
      verifiedTxt: "",
      existId: 0,
      form: {
        userProfile: {
          userGUID: null,
          firstname: null,
          lastname: null,
          email: null,
          telephone: null,
          isVerified: false,
          password: "",
          confirmPassword: "",
          enabled: true,
          birthday: "0001-01-01T00:00:00",
          isVerifyEmail: false,
          imageUrl: null,
          isConsent: false,
          statusId: 0,
        },
        permission: [],
      },
      formPassword: {
        userId: null,
        password: "",
        confirmPassword: "",
      },
    };
  },
  computed: {
    ...mapState({
      profileInfo: (state) => state.profile,
    }),
  },
  created: async function () {
    this.$isLoading = true;
    await this.getDatas();
  },
  validations: {
    form: {
      userProfile: {
        firstname: { required },
        lastname: { required },
        email: {
          email,
          required: requiredIf(function () {
            return this.id == 0;
          }),
        },
        telephone: { required, minLength: minLength(10), integer },
        password: {
          required: requiredIf(function () {
            return this.id == 0;
          }),
          minLength: minLength(8),
          password,
        },
        confirmPassword: {
          required: requiredIf(function () {
            return this.id == 0;
          }),
          minLength: minLength(8),
          password,
          sameAsPassword: sameAs("password"),
        },
      },
    },
    formPassword: {
      userId: { required },
      password: {
        required: requiredIf(function () {
          return this.id != 0;
        }),
        minLength: minLength(8),
        password,
      },
      confirmPassword: {
        required: requiredIf(function () {
          return this.id != 0;
        }),
        minLength: minLength(8),
        password,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addParent(ref) {
      this.parentList.push(ref);
    },
    deleteParent(ref, level) {
      var index = this.parentList.indexOf(ref);
      if (index !== -1) this.parentList.splice(index, 1);
      if (level && level == 1) {
        this.menus.forEach((element) => {
          if (element.subMenu) {
            element.subMenu.forEach((element2) => {
              if (this.parentList.indexOf(element2.id) > -1) {
                this.parentList.splice(this.parentList.indexOf(element2.id), 1);
              }
            });
          }
        });
      }
    },

    checkSubMenuChecked: function (val, subMenu) {
      let indexTarget = this.form.permission.findIndex((el) => el == val);

      if (indexTarget > -1) {
        this.form.permission.splice(indexTarget, 1);
        if (subMenu) {
          subMenu.forEach((element) => {
            var index = this.form.permission.findIndex(
              (el) => el == element.id
            );
            if (index > -1) {
              this.form.permission.splice(index, 1);
            }

            if (element.subMenu) {
              element.subMenu.forEach((element2) => {
                var index2 = this.form.permission.findIndex(
                  (el) => el == element2.id
                );
                if (index2 > -1) {
                  this.form.permission.splice(index2, 1);
                }
              });
            }
          });
        }
      } else {
        this.form.permission.push(val);
        if (subMenu) {
          subMenu.forEach((element) => {
            let indexTarget2 = this.form.permission.findIndex(
              (el) => el == element.id
            );
            if (indexTarget2 < 0) {
              this.form.permission.push(element.id);
              if (element.subMenu) {
                element.subMenu.forEach((element2) => {
                  let indexTarget3 = this.form.permission.findIndex(
                    (el) => el == element2.id
                  );
                  if (indexTarget3 < 0) {
                    this.form.permission.push(element2.id);
                  }
                });
              }
            }
          });
        }
      }
    },
    getDatas: async function () {
      this.$isLoading = false;

      let menuHirachy = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/admin/menuHierarchy`,
        null,
        this.$headers,
        null
      );

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/admin/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail;
        this.formPassword.userId = this.form.userProfile.userGUID;
        this.$isLoading = true;
        // console.log(this.form);
      }

      if (menuHirachy.result == 1) {
        this.menus = menuHirachy.detail.mainMenu;
      }

      if (this.id == 0) {
        this.form.userProfile.enabled = true;
        this.onSetPermission();
      }
    },
    onSetPermission() {
      this.menus.forEach((menu) => {
        this.form.permission.push(menu.id);
        if (menu.subMenu) {
          menu.subMenu.forEach((menu2) => {
            this.form.permission.push(menu2.id);
            if (menu2.subMenu) {
              menu2.subMenu.forEach((menu3) => {
                this.form.permission.push(menu3.id);
              });
            }
          });
        }
      });
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.submit();
    },
    checkFormPassword: async function () {
      this.$v.formPassword.$touch();
      if (this.$v.formPassword.$error) return;
      this.submitChangePassword();
    },
    submitChangePassword: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/admin/changePasswordAdmin`,
        null,
        this.$headers,
        this.formPassword
      );
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.$v.formPassword.$reset();
        this.modalMessage = "สำเร็จ";
        this.formPassword.password = "";
        this.formPassword.confirmPassword = "";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
      } else {
        if (data.detail !== null) {
          this.modalMessage = data.detail[0];
        } else {
          this.modalMessage = data.message;
        }
        this.$refs.modalAlertError.show();
      }
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/admin/save`,
        null,
        this.$headers,
        this.form
      );
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.existId = data.detail;
        this.modalMessage = "สำเร็จ";
        this.getDatas();
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        if (this.id !== 0) {
          this.$v.form.$reset();
          this.getDatas();
        } else {
          this.form.userProfile.userGUID = this.existId;
          this.id = this.existId;
          this.isEdit = true;
          this.$router.push({ path: `/admin/details/${this.existId}` });
        }
      } else {
        if (data.detail !== null) {
          this.modalMessage = data.detail[0];
        } else {
          this.modalMessage = data.message;
        }
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
.menu {
  display: block;
  margin: auto;
}
.forget-pass-txt {
  position: absolute;
  right: 15px;
  bottom: 30px;
  text-decoration: underline;
  cursor: pointer;
}

.store-type-box {
  border: 1px solid #bcbcbc;
  height: 400px;
  overflow-y: auto;
}

.parent-cat {
  display: inline-block;
  position: relative;
  top: 5px;
  margin-left: 10px;
}
.verified-txt {
  position: absolute;
  right: 15px;
  top: 3px;
}
.detail-password {
  font-size: 12px;
}
::v-deep .hastextFloat {
  top: 32px;
  right: 10px;
}
@media (max-width: 600px) {
  .forget-pass-txt {
    bottom: -5px;
  }
}
</style>
